import axios from 'axios';
import { getAPIHost, getMEURL } from '../services/id';
export const getHttpClient = (options = {}) => {
    return axios.create({
        baseURL: `${getAPIHost()}/api`,
        timeout: 30 * 1000, // default 30s
        ...options,
    });
};
export const getMeHttpClient = (options = {}) => {
    const { headers: headersOverride, ...restOptions } = options;
    return axios.create({
        baseURL: getMEURL(),
        timeout: 30 * 1000, // default 30s
        headers: {
            authorization: `Bearer ${process.env.ADMIN_KEY}`,
            ...headersOverride,
        },
        ...restOptions,
    });
};
/**
 * @example
 *    try {
 *      const res = await axios.get(path)
 *      return res.data
 *    } catch (err) {
 *      const [errStatus, errCode, originalErrMsg, value] = handleHttpClientError(err)
 *      return value;
 *    }
 *
 *  @returns tuple error status code, HttpClientErrorCode, original error message, response err msg string
 */
export const handleHttpClientError = (err) => {
    if (axios.isAxiosError(err)) {
        const resStatus = err.response?.status;
        const resMsg = err.response?.data?.msg ?? err.response?.data?.message ?? null;
        if (resStatus === 404) {
            return [404, 'notFound', err.message, resMsg];
        }
        if (resStatus === 400) {
            return [400, 'badRequest', err.message, resMsg];
        }
        if (resStatus === 401) {
            return [401, 'unauthorized', err.message, resMsg];
        }
        if (resStatus === 403) {
            return [403, 'forbiden', err.message, resMsg];
        }
        if (resStatus === 422) {
            return [422, 'unprocessableEntity', err.message, resMsg];
        }
        if (resStatus === 429) {
            return [429, 'tooManyRequests', err.message, resMsg];
        }
        if (resStatus === 500) {
            return [500, 'unknownError', err.message, resMsg];
        }
    }
    // won't handle other errors, rethrow
    return [null, 'unhandled', 'unhandled HTTP client error', null];
};
