import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import '@solana/wallet-adapter-react-ui/styles.css';
import { Cluster } from '@solana/web3.js';
import { ReactNode, useCallback, useMemo } from 'react';
import { clusterApiUrl } from '../utils/clusterApiUrl';
import { useCluster } from './Cluster';

export type IWalletProviderProps = {
  cluster: Cluster;
};

const WP = ({ children }: { children: ReactNode }) => {
  const { cluster } = useCluster();
  const wallets = useMemo(() => {
    return [];
  }, []);

  const onError = useCallback((error: unknown, adapter?: unknown) => {
    console.error(error, adapter);
  }, []);

  return (
    <ConnectionProvider endpoint={clusterApiUrl(cluster)}>
      <WalletProvider wallets={wallets} onError={onError} autoConnect={false}>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

/**
 * Expose
 */

export default WP;
