import axios from 'axios';
import { getMEURL } from '../services';
export async function getNFTsByHashList(hashList, includeBurntMint) {
    const fetches = hashList.map((hash) => {
        // TODO: consider to use rpc/getNFTByMintAddresses to reduce number of HTTP requests
        // https://github.com/magiceden/magiceden/blob/main/js/packages/api/src/routes/coreTrading.route.ts#L24
        return axios
            .get(`${getMEURL()}/rpc/getNFTByMintAddress/${hash}?includeBurntMint=${includeBurntMint}`)
            .then(res => res.data);
    });
    const values = await Promise.allSettled(fetches);
    return values
        .map((value) => (value.status === 'fulfilled' ? value.value.results : undefined))
        .filter((value) => value !== null && value !== undefined);
}
