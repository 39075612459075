import axios from 'axios';
import set from 'lodash/set';
export var FeatureFlagVariants;
(function (FeatureFlagVariants) {
    FeatureFlagVariants["On"] = "on";
    FeatureFlagVariants["Off"] = "off";
})(FeatureFlagVariants || (FeatureFlagVariants = {}));
export const FeatureFlags = {
    creatorPortalLaunchpadForm: FeatureFlagVariants.Off,
    creatorHubWhitelist: FeatureFlagVariants.Off,
    ethDropCalendar: FeatureFlagVariants.Off,
    chSaveAsDraft: FeatureFlagVariants.Off,
    creatorHubBadgingApply: FeatureFlagVariants.Off,
    creatorHubBadgingTxnVolOverride: FeatureFlagVariants.Off,
    whitelistCreatorTwitter: FeatureFlagVariants.Off,
    whitelistCreatorNameInput: FeatureFlagVariants.Off,
    whitelistCreatorBlockchainSelector: FeatureFlagVariants.Off,
    syncToLMNFT: FeatureFlagVariants.Off,
    whitelistCreatorNftOwnedRequirement: FeatureFlagVariants.Off,
    createWhitelistDuringDropList: FeatureFlagVariants.Off,
    shouldCallUpsertCollectionsV1: FeatureFlagVariants.Off,
    shouldCallUpsertCollectionsV2: FeatureFlagVariants.Off,
    whitelistOpportunitiesSelfServe: FeatureFlagVariants.Off,
    creatorHubEnableRaffleSelfServe: FeatureFlagVariants.Off,
    creatorHubEnableRaffleV2Display: FeatureFlagVariants.Off,
    whitelistOpportunitiesSelfServeCreator: FeatureFlagVariants.Off,
    employeesOnly: FeatureFlagVariants.Off,
    enablePolygonCollectionCreatorHub: FeatureFlagVariants.Off,
    enableBitcoinCollectionCreatorHub: FeatureFlagVariants.Off,
    enableEthereumCollectionCreatorHub: FeatureFlagVariants.Off,
    /**
     * creator hub announcements self-serve feature
     */
    CHASS: FeatureFlagVariants.Off,
    announcementStats: FeatureFlagVariants.Off,
    /**
     * https://flagr-admin.magiceden.io/#/flags/502
     */
    kmgo9fksziyvn7p8e: FeatureFlagVariants.Off,
    excludeBtcAnnouncements: FeatureFlagVariants.Off,
    enableAiAutoReject: FeatureFlagVariants.Off,
    enableAiAssessment: FeatureFlagVariants.Off,
    enableReservoirUnifiedSearch: FeatureFlagVariants.Off,
    /**
     * [ORD] creator tips switch
     * https://flagr-admin.magiceden.io/#/flags/695
     */
    kx8xqay17hh58687u: FeatureFlagVariants.Off,
    /**
     * [CH] ownership transfer with Update Authority
     * https://flagr-admin.magiceden.io/#/flags/830
     */
    k955g2yv3goc9ecz6: FeatureFlagVariants.Off,
    /**
     * [CH] [CH] EVM CH launch ui-creatorhub
     * https://flagr-admin.magiceden.io/#/flags/873
     */
    kqqsoii1icyvhjh71: FeatureFlagVariants.Off,
    /**
     * [CH] Badged Collection Minimum Volume Threshold
     * https://flagr-admin.magiceden.io/#/flags/912
     */
    badgedCollectionMinVolThreshold: FeatureFlagVariants.Off,
};
export const FeatureFlagKeys = Object.keys(FeatureFlags).reduce((result, key) => set(result, key, key), {});
export const FLAG_KEYS = Object.keys(FeatureFlagKeys);
const FLAGR_URL = process.env.FLAGR_URL || 'https://flagr.magiceden.io/api/v1/evaluation';
const FLAGR_BATCH_URL = FLAGR_URL + '/batch';
export async function evaluateFlag({ key, entityContext, entityID, entityType, }) {
    try {
        const response = await axios.post(FLAGR_URL, {
            entityID,
            entityType,
            ...(entityContext && { entityContext }),
            flagKey: key,
        });
        return response.data.variantKey ?? null;
    }
    catch {
        return null;
    }
}
export async function evaluateFlags(flagKeys, entity = {}) {
    const flags = flagKeys.reduce((result, flag) => set(result, flag, null), {});
    try {
        const { data } = await axios.post(FLAGR_BATCH_URL, {
            flagKeys,
            entities: [entity],
        });
        for (const result of data.evaluationResults) {
            flags[result.flagKey] = result.variantKey ?? null;
        }
    }
    finally {
        return flags;
    }
}
